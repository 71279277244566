<script setup lang="ts">
const { locale } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const route = useRoute()
const logoLayout = computed(() => {
  if (route.name?.toString().includes('login'))
    return ''
  if (route.name?.toString().includes('dev'))
    return 'dev'
  if (route.name?.toString().includes('pro'))
    return 'pro'
})
</script>

<template>
  <div class="flex">
    <Html>
      <Body class="leading-7 text-gray-700 bg-gray-100 font-body antialiased">
        <div class="flex flex-col flex-1 lg:min-w-[400px] min-h-screen px-4 bg-white">
          <div class="flex justify-between px-2 pt-4">
            <div class="flex items-center">
              <Transition
                mode="out-in"
                enter-active-class="transition duration-150 ease-in-out"
                leave-active-class="transition duration-150 ease-in-out"
                enter-from-class="opacity-0"
                leave-to-class="opacity-0"
              >
                <picture v-if="logoLayout === 'dev' && locale === 'fr'">
                  <source
                    media="(min-width: 768px)"
                    srcset="/logo/appguide-dev-fr.svg"
                    width="285"
                  >
                  <img
                    src="/logo/appguide-dev-fr-sm.svg"
                    alt="AppGuide pour développeurs"
                    width="169"
                  >
                </picture>
                <picture v-else-if="logoLayout === 'dev'">
                  <source
                    media="(min-width: 768px)"
                    srcset="/logo/appguide-dev-en.svg"
                    width="250"
                  >
                  <img
                    src="/logo/appguide-dev-en-sm.svg"
                    alt="AppGuide for developers"
                    width="169"
                  >
                </picture>
                <img
                  v-else
                  src="/logo/full.svg"
                  alt="AppGuide"
                  width="162"
                  height="32"
                >
              </Transition>

              <svg
                v-if="logoLayout === 'pro'"
                width="41"
                height="22"
                viewBox="0 0 41 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ml-1.5"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="40"
                  height="21"
                  rx="10.5"
                  fill="#E02267"
                />
                <path d="M27.7663 15.1823C26.9985 15.1823 26.2927 15.0039 25.6489 14.6472C25.0129 14.2904 24.5049 13.794 24.1248 13.158C23.7526 12.5142 23.5664 11.7929 23.5664 10.994C23.5664 10.1952 23.7526 9.47772 24.1248 8.84172C24.5049 8.20572 25.0129 7.70933 25.6489 7.35255C26.2927 6.99577 26.9985 6.81738 27.7663 6.81738C28.5342 6.81738 29.2361 6.99577 29.8721 7.35255C30.5159 7.70933 31.02 8.20572 31.3846 8.84172C31.7568 9.47772 31.943 10.1952 31.943 10.994C31.943 11.7929 31.7568 12.5142 31.3846 13.158C31.0123 13.794 30.5081 14.2904 29.8721 14.6472C29.2361 15.0039 28.5342 15.1823 27.7663 15.1823ZM27.7663 13.3674C28.4178 13.3674 28.9375 13.1502 29.3253 12.7159C29.7209 12.2816 29.9187 11.7076 29.9187 10.994C29.9187 10.2727 29.7209 9.69877 29.3253 9.27219C28.9375 8.83785 28.4178 8.62068 27.7663 8.62068C27.1071 8.62068 26.5797 8.83397 26.1841 9.26055C25.7963 9.68714 25.6024 10.265 25.6024 10.994C25.6024 11.7154 25.7963 12.2932 26.1841 12.7275C26.5797 13.1541 27.1071 13.3674 27.7663 13.3674Z" fill="white" />
                <path d="M20.4775 15.1008L18.7789 12.0177H18.3019V15.1008H16.3125V6.93359H19.6515C20.2953 6.93359 20.8421 7.04606 21.2919 7.27098C21.7495 7.49591 22.0908 7.80615 22.3157 8.20172C22.5406 8.58952 22.6531 9.02386 22.6531 9.50474C22.6531 10.0477 22.498 10.5324 22.1877 10.959C21.8853 11.3856 21.4354 11.6881 20.8382 11.8665L22.7229 15.1008H20.4775ZM18.3019 10.61H19.5352C19.8997 10.61 20.1712 10.5208 20.3495 10.3424C20.5357 10.164 20.6288 9.91194 20.6288 9.58618C20.6288 9.27594 20.5357 9.03162 20.3495 8.85323C20.1712 8.67484 19.8997 8.58564 19.5352 8.58564H18.3019V10.61Z" fill="white" />
                <path d="M15.279 9.56291C15.279 10.036 15.1704 10.4704 14.9532 10.8659C14.736 11.2537 14.4025 11.5679 13.9527 11.8083C13.5028 12.0487 12.9444 12.169 12.2773 12.169H11.0441V15.1008H9.05469V6.93359H12.2773C12.9289 6.93359 13.4795 7.04606 13.9294 7.27098C14.3792 7.49591 14.7166 7.80615 14.9416 8.20172C15.1665 8.59728 15.279 9.05101 15.279 9.56291ZM12.1261 10.5867C12.5062 10.5867 12.7892 10.4975 12.9754 10.3191C13.1615 10.1407 13.2546 9.88867 13.2546 9.56291C13.2546 9.23715 13.1615 8.98508 12.9754 8.80669C12.7892 8.6283 12.5062 8.53911 12.1261 8.53911H11.0441V10.5867H12.1261Z" fill="white" />
              </svg>
            </div>

            <Button
              v-if="locale === 'en'"
              :to="switchLocalePath('fr')"
              level="3"
              variant="ghost"
              class="ml-3"
            >
              <i-translate />
              Français
            </Button>
            <Button
              v-else
              :to="switchLocalePath('en')"
              level="3"
              variant="ghost"
            >
              <i-translate />
              English
            </Button>
          </div>
          <div class="flex-none w-full max-w-sm mx-auto mt-12 2xl:mt-28 mb-12">
            <slot />
          </div>
        </div>

        <div class="hidden flex-shrink sticky top-0 w-full max-w-md xl:max-w-lg 2xl:max-w-xl min-h-screen max-h-screen lg:block bg-[#312F4E]">
          <img src="/assets/textures/auth-layout.webp" role="presentation" class="relative z-0 w-full h-full object-fit opacity-30">
          <div class="absolute inset-y-0 left-0 w-44 bg-gradient-to-r from-violet-900 to-violet-900/0 mix-blend-multiply opacity-30" />
        </div>
      </Body>
    </Html>
  </div>
</template>

<style scoped lang="scss">
#textured-background {
  background-image: url('/assets/textures/auth-layout.webp');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
}
</style>
